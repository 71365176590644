.blocktwo {
  padding: 60px 0px;
  background-color: #f4f5f7;
}
.blocktwo-text {
  padding: 20px 50px;
}
.blocktwo-text-img img {
  width: 100%;
}

@media (max-width: 992px) {
  .blocktwo-text {
    padding: 10px 5px;
  }
}
