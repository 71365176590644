.blockfour {
  background-color: #e3eeff;
  padding: 60px 0px;
}

.blockfour-image svg {
  width: 40px;
  height: 45px;
  color: #4c9aff;
}
.blockfour-items {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding-top: 50px;
}
.blockfour-item-area {
  min-height: 300px;
  border-radius: 7px;
}
.blockfour-subtitle {
  color: var(--c-three);
}

@media (min-width: 992px) and (max-width: 1200px) {
  .blockfour .a-text-600-4 {
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blockfour .a-text-600-4 {
    font-weight: 600;
    line-height: 1.2;
    font-size: 1.4rem;
  }
  .blockfour-items {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 50px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .blockfour .a-text-600-4 {
    font-weight: 600;
    line-height: 1.2;
    font-size: 1rem;
  }
  .blockfour-items {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 50px;
  }
  .blockfour-image svg {
    width: 25px;
    height: 30px;
    color: #4c9aff;
  }
  .blockfour .a-text-400-2 {
    font-weight: 400;
    line-height: 1.5;
    font-size: 0.9rem;
  }
}

@media (max-width: 575px) {
  .blockfour .a-text-600-4 {
    font-weight: 600;
    line-height: 1.2;
    font-size: 1rem;
  }
  .blockfour-items {
    display: block;
    padding-top: 20px;
  }
  .blockfour-image svg {
    width: 25px;
    height: 30px;
    color: #4c9aff;
  }
  .blockfour .a-text-400-2 {
    font-weight: 400;
    line-height: 1.5;
    font-size: 0.9rem;
  }
  .blockfour-item-area {
    min-height: 150px;
  }
}
