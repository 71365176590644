.footer {
  background-color: var(--c-twelve);
  min-height: 250px;
  color: var(--c-one);
  font-size: 13px;
}
.footer-contact-item {
  color: var(--c-thirteen);
}
.footer-in_rf img {
  width: 250px;
}
