/************************************
***************Button****************
************************************/

/************************************
**************Position***************
************************************/
.a-flex-20 {
  display: flex;
  gap: 20px;
}
.a-relative {
  position: relative;
}
.a-text-center {
  text-align: center;
}

/************************************
****************Text*****************
************************************/

.a-text-600-1 {
  font-weight: 600;
  line-height: 1.2;
  font-size: 2.4rem;
}
.a-text-600-2 {
  font-weight: 600;
  line-height: 1.2;
  font-size: 2.2rem;
}
.a-text-600-3 {
  font-weight: 600;
  line-height: 1.2;
  font-size: 2rem;
}
.a-text-600-4 {
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.8rem;
}
.a-text-600-5 {
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.6rem;
}
.a-text-600-6 {
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.4rem;
}
.a-text-600-7 {
  font-weight: 600;
  line-height: 1.2;
  font-size: 1.2rem;
}
.a-text-600-8 {
  font-weight: 600;
  line-height: 1.5;
  font-size: 1rem;
}
.a-text-400-1 {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.2rem;
}
.a-text-400-2 {
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
}
.a-text-400-3 {
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
}
.a-section-title {
  padding-top: 20px;
  padding-bottom: 10px;
}

.a-section-text {
  padding-top: 10px;
  padding-bottom: 20px;
}

.a-fw-600 {
  font-weight: 600;
}

/************************************
****************border***************
************************************/

.a-border-background {
  background-color: var(--c-one);
}
.border_bg_b_c_three {
  background-color: var(--c-one);
  border-bottom: 1px solid var(--c-three);
}
.border_bg_r_c_eleven {
  border-right: 1px solid var(--c-eleven);
}
.a-gradient {
  background-image: linear-gradient(180deg, #ffffff, #deebff);
}

.a-cursor {
  cursor: pointer;
}
.a-transition-03 {
  transition: 0.3s;
}
