:root {
  --c-one: rgb(255, 255, 255);
  --c-two: rgb(245, 245, 245);
  --c-three: rgb(22, 119, 255);
  --c-three-hover: rgb(2, 91, 216);
  --c-four: rgb(35, 121, 115);
  --c-fife: rgb(255, 17, 0);
  --c-six: rgb(109, 109, 109);
  --c-seven: rgb(0, 128, 0);
  --c-eight: rgb(236, 244, 255);
  --c-nine: rgb(204, 204, 204);
  --c-nine-light: rgb(242, 242, 242);
  --c-ten: rgb(132, 183, 255);
  --c-eleven: rgb(70, 70, 70);
  --c-twelve: rgb(45, 45, 45);
  --c-thirteen: rgb(175, 175, 175);
}
