.blockquestions {
  padding: 60px 0px;
}
.blockquestions-svg {
  position: absolute;
  right: 5px;
  top: 20px;
}
.blockquestions-svg svg {
  width: 25px;
  height: 25px;
}
.blockquestions-answer {
  height: 0px;
  overflow: hidden;
}

.blockquestions-active .blockquestions-answer {
  height: auto;
  padding: 25px 0px;
}
.faangleup {
  display: none;
}
.blockquestions-active .faangleup {
  display: block;
}
.blockquestions-active .faangledown {
  display: none;
}
.blockquestions-items {
  padding: 50px 120px;
}
.blockquestions-item {
  display: grid;
  grid-template-columns: 90% 10%;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .blockquestions-items {
    padding: 50px 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .blockquestions-items {
    padding: 50px 5px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .blockquestions-items {
    padding: 50px 5px;
  }
  .blockquestions .a-text-600-5 {
    font-size: 1.3rem;
  }
}

@media (max-width: 575px) {
  .blockquestions-items {
    padding: 50px 20px;
  }
  .blockquestions .a-text-600-5 {
    font-size: 1.2rem;
  }
}
