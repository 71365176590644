.App-header {
  background-color: #2b3035;
  border-bottom: 1px solid #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  position: sticky;
  top: 0px;
  z-index: 100;
}
.logo-description-block {
  display: flex;
  gap: 20px;
}
.app-header-logo {
  width: 45px;
  height: 35px;
}
.app-header-description {
  font-size: 12px;
}
.btn-item {
  color: #002559;
  padding: 5px 15px;
  border-radius: 3px;
  cursor: pointer;
  width: max-content;
  background-color: #ffab00;
}

.btn-block-header:hover {
  background-color: var(--c-three-hover);
  color: var(--c-one);
}

.btn-block-header:active {
  box-shadow: 0px 0px 7px 1px var(--c-three);
}
.button-block {
  display: flex;
  gap: 20px;
  justify-content: end;
}

.app-header-menu-items {
  display: flex;
  gap: 30px;
  align-items: center;
  color: var(--c-nine);
  font-size: 14px;
}
.app-header-menu-block-items {
  display: flex;
  justify-content: end;
  height: 100%;
}

.menu-item {
  cursor: pointer;
}
.middle {
  background: var(--c-one);
  height: 1px;
  width: 25px;
}

.middle::before {
  content: " ";
  background: var(--c-one);
  height: 1px;
  position: absolute;
  top: -5px;
  width: 25px;
}

.middle::after {
  content: " ";
  background: var(--c-one);
  height: 1px;
  position: absolute;
  top: 5px;
  width: 25px;
}
.burger {
  transition: 0.5s;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  padding: 5px;
}
.burger-button {
  height: 20px;
  line-height: 18px;
  position: relative;
  cursor: pointer;
  width: 20px;
  z-index: 20;
}
.burger.active .middle::after {
  transform: rotate(45deg);
  right: 13px;
  top: 3px;
  width: 10px;
  transition: 0.5s;
}
.burger.active .middle::before {
  transform: rotate(-45deg);
  right: 13px;
  top: -3px;
  width: 10px;
  transition: 0.5s cubic-bezier(0, 3.77, 0.93, 1.38);
}
.burger.active .middle {
  background: #000;
}
.burger.active .middle::before {
  background: #000;
}
.burger.active .middle::after {
  background: #000;
}
.menu-block-state {
  opacity: 0;
  height: 0px;
}

.menu-block-state.menu-block-state-active {
  display: block !important;
  opacity: 1;
  position: absolute;
  transition: 0.5s;
  border-radius: 10px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
    0 1px 14px 0 rgba(0, 0, 0, 0.12);
  right: 0px;
  padding: 12px 16px;
  top: 0px;
  z-index: 1;
  background: #fff;
  padding-top: 60px;
  width: 90%;
  height: 100vh;
  top: 0;
  bottom: 0;
  min-width: max-content;
}

.menu-block-state-active .app-header-menu-items {
  display: block;
}
.menu-block-state-active .button-block {
  justify-content: normal;
  padding-top: 30px;
}
.menu-block-state-active .menu-item {
  color: #000;
  padding: 10px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

.menu-block-state-active .app-header-menu-block-items {
  display: block;
}
.logo-burger {
  display: none;
}
.menu-block-state-active .logo-burger {
  display: block;
  position: absolute;
  top: 10px;
  color: #575757;
}
