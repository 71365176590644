.blocksix {
  padding: 60px 0px;
  background-image: linear-gradient(180deg, #ffffff, #deebff);
}
.blocksix img {
  width: 100%;
}
.blocksix-itemtext {
  display: grid;
  grid-template-columns: 70px auto;
}
.blocksix-itemtext-svg {
  align-items: center;
  display: flex;
}
.blocksix-itemtext-svg svg {
  width: 50px;
  height: 50px;
  color: #62a7ff;
}

.blocksix-description-title {
  text-align: center;
}
.blocksix-itemtext-block {
  font-size: 14px;
}
.block-img-area {
  height: 100%;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .blocksix .a-section-title {
    padding-top: 0px;
  }
  .blocksix-block-items {
    padding-top: 0px;
  }
}
