.mainbanner {
  background-color: rgb(43 48 53);
  padding: 60px 0px;
}
.mainbanner-text {
  padding: 60px 0px;
  color: var(--c-one);
}
.mainbanner-video {
  padding: 60px 30px;
}
.mainbanner-image {
  justify-content: center;
  display: flex;
}
.mainbanner-image img {
  border-left: 1px solid var(--c-one);
  padding: 10px 15px 10px 45px;
  justify-content: center;
  display: flex;
  width: 70%;
}

.image-if-expend {
  border-left: 0px solid var(--c-one) !important;
  width: 100% !important;
}

.mainbanner-btn {
  color: #002559;
  padding: 5px 15px;
  border-radius: 3px;
  width: max-content;
  background-color: #ffab00;
}

.mainbanner-btn:hover {
  background-color: var(--c-three-hover);
  color: var(--c-one);
}

.mainbanner-btn:active {
  box-shadow: 0px 0px 7px 1px var(--c-three);
}

.mainbanner-video video {
  width: 100%;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .mainbanner-text {
    padding: 10px 0px;
  }
  .mainbanner-video {
    padding: 10px 0px;
  }
  .mainbanner .a-text-600-1 {
    font-size: 1.8rem;
  }
}
