.blockfive {
  padding: 60px 0px;
}
.blockfive-container {
  padding: 0px 15px;
}
.blockfive-items {
  display: flex;
  overflow: overlay;
}
.blockfive-item {
  border: 1px solid #dfe1e5;
  min-width: 300px;
}
.btn-block-tariff {
  justify-content: center;
  display: flex;
  color: var(--c-three);
  padding: 5px 15px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid var(--c-three);
}

.btn-block-tariff:hover {
  background-color: var(--c-three-hover);
  color: var(--c-one);
}

.btn-block-tariff:active {
  box-shadow: 0px 0px 7px 1px var(--c-three);
}
.blockfive-tariff-top-block {
  text-align: center;
  min-height: 290px;
}

@media (max-width: 768px) {
  .blockfive-tariff-top-block {
    min-height: 210px;
  }
}
