.blockthree {
  padding: 60px 0px;
}

.blockthree-text-img img {
  width: 100%;
  box-shadow: 5px 5px 5px #bebebe;
}

.blockthree-items {
  padding-top: 50px;
}

.blockthree-text-items {
  display: flex;
  align-items: center;
  height: 100%;
}

@media (min-width: 768px) and (max-width: 991px) {
  .blockthree .a-text-600-4 {
    font-size: 1.1rem;
  }
  .blockthree .a-text-400-2 {
    font-size: 0.75rem;
  }
}
